var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atmos-promotion-card" }, [
    _c("div", { staticClass: "fw500 flex bet a-center" }, [
      _c("div", {
        style: { "margin-top": "10px", "margin-right": "15px" },
        domProps: { textContent: _vm._s(_vm.item.name) },
      }),
    ]),
    _c(
      "div",
      {
        staticClass: "atmos-promotion-card__main",
        style: { width: "100%", display: "flex", "margin-right": "45px" },
      },
      [
        _c(
          "div",
          {
            staticClass: "atmos-promotion-card__main_main",
            style: { width: "100%" },
          },
          [
            _c(
              "div",
              {
                staticClass: "atmos-promotion-card__main_name",
                on: { click: _vm.data.click },
              },
              [
                _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Trigger type")) },
                }),
                _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(_vm.triggerToText(_vm.item.trigger)).toString()
                    ),
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "atmos-promotion-card__main_name",
                on: { click: _vm.data.click },
              },
              [
                _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Code coupon")) },
                }),
                _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.item.coupon_settings &&
                        _vm.item.coupon_settings.reusable_code
                        ? _vm.item.coupon_settings.reusable_code
                        : "-"
                    ),
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "atmos-promotion-card__main_name",
                on: { click: _vm.data.click },
              },
              [
                _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Start date")) },
                }),
                _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.item.start_date
                        ? _vm.filters.date(_vm.item.start_date, "DD/MM/YYYY") +
                            " " +
                            _vm.$t("At") +
                            " " +
                            _vm.filters.date(_vm.item.start_date, "HH:mm")
                        : "-"
                    ),
                  },
                }),
              ]
            ),
            _c("div", {
              staticClass: "atmos-promotion-card__main_name",
              on: { click: _vm.data.click },
            }),
            _c(
              "div",
              {
                staticClass: "atmos-promotion-card__main_name",
                on: { click: _vm.data.click },
              },
              [
                _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("End date")) },
                }),
                _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.item.end_date
                        ? _vm.filters.date(_vm.item.end_date, "DD/MM/YYYY") +
                            " " +
                            _vm.$t("At") +
                            " " +
                            _vm.filters.date(_vm.item.end_date, "HH:mm")
                        : "-"
                    ),
                  },
                }),
              ]
            ),
            _c("div", {
              staticClass: "atmos-promotion-card__main_name",
              on: { click: _vm.data.click },
            }),
            _c(
              "div",
              {
                staticClass: "atmos-promotion-card__main_name",
                on: { click: _vm.data.click },
              },
              [
                _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Created at")) },
                }),
                _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.item.created_at
                        ? _vm.filters.date(_vm.item.created_at, "DD/MM/YYYY") +
                            " " +
                            _vm.$t("At") +
                            " " +
                            _vm.filters.date(_vm.item.created_at, "HH:mm")
                        : "-"
                    ),
                  },
                }),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }